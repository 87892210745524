<template>
<div>
  <div class="my-5 mx-5" v-if="!permission.read">
    <h1>You do not have access to this regulation</h1>
  </div>
  <div class="my-2" v-if="permission.read">
  <b-container fluid>
  <b-row class="row text-white bg-info">
    <b-col align-self="center" sm="2" class=" cols text-center" align-v="center">
      <h1 justify="center" align="center" class="kstyle title justify-content-md-center">Obligations</h1>
    </b-col>
    <b-col sm="8" align-self="center" align-v="center" class="search text-center cols justify-content-md-center">
      <b-input-group>
          <b-form-input v-model="searchFilter" placeholder="Search......" />
          <b-btn :disabled="!searchFilter" @click="searchFilter = ''">clear</b-btn>
      </b-input-group>
    </b-col>
    <b-col sm="2">
    </b-col>
  </b-row>
    <b-row v-if="loading">
      <b-col>
        <b-container class="text-center">
          <h1>Loading data</h1>
          <h3>... loaded: {{annotations.length}} ...</h3>
          <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
        </b-container>
      </b-col>
    </b-row>
  <b-row class="mb-4" v-if="!loading">
    <b-col>
    <b-row class="text-white bg-info">
      <b-col align-self="center" cols="2" class="cols text-center" align-v="center"></b-col>
      <b-col align-self="center" cols="8" class="cols text-center" align-v="center">
        <b-button v-b-toggle.sidebar-countries class="filter-button">Countries <b-badge v-if="countrySelectedDisplay!='all'" variant="light">{{countrySelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-countries" title="Countries" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setCountriesSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setCountriesSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="countrySelected"
                      :options="countryOptionsDynamic"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-button v-b-toggle.sidebar-regulators class="filter-button">Regulators <b-badge v-if="regulatorSelectedDisplay!='all'" variant="light">{{regulatorSelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-regulators" title="Regulators" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setRegulatorsSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setRegulatorsSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="regulatorSelected"
                      :options="regulatorOptionsDynamic"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-button v-b-toggle.sidebar-regulations class="filter-button">Regulations <b-badge v-if="regulationSelectedDisplay!='all'" variant="light">{{regulationSelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-regulations" title="Regulations" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setRegulationsSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setRegulationsSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="regulationSelected"
                      :options="regulationOptionsDynamic"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-button v-b-toggle.sidebar-workflowstatus class="filter-button">Workflow Status <b-badge v-if="workflowStatusSelectedDisplay!='all'" variant="light">{{workflowStatusSelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-workflowstatus" title="Workflow Status" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setWorkflowStatusSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setWorkflowStatusSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="workflowStatusSelected"
                      :options="workflowStatusOptions"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-button v-b-toggle.sidebar-type class="filter-button">Types <b-badge v-if="typesSelectedDisplay!='all'" variant="light">{{typesSelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-type" title="Types" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setTypeFilterSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setTypeFilterSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="typeFilterSelected"
                      :options="typesOptionsDynamic"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-button v-b-toggle.sidebar-area class="filter-button">Areas <b-badge v-if="areasSelectedDisplay!='all'" variant="light">{{areasSelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-area" title="Areas" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setAreasSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setAreasSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="areasSelected"
                      :options="areasOptionsDynamic"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-button v-b-toggle.sidebar-entities class="filter-button">Entities <b-badge v-if="entitiesSelectedDisplay!='all'" variant="light">{{entitiesSelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-entities" title="Entities" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setEntitiesSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setEntitiesSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="entitiesSelected"
                      :options="entitiesOptionsDynamic"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-button v-b-toggle.sidebar-requirements class="filter-button">Requirements <b-badge v-if="requirementsSelectedDisplay!='all'" variant="light">{{requirementsSelectedDisplay}}</b-badge></b-button>
          <b-sidebar id="sidebar-requirements" title="Requirements" backdrop backdrop-variant="transparent" shadow width="25%">
            <div class="px-3 py-2">
              <!-- <b-input-group>
                <b-form-input type="search" v-model="filterCountries" placeholder="Type to Search" />
                <b-btn :disabled="!filterCountries" @click="filterCountries = ''">clear</b-btn>
              </b-input-group> -->
                <b-button-group b-button-group class="mt-3">
                  <b-button variant="outline-primary" class="text-center" v-on:click="setRequirementsSelected('all')">all</b-button>
                  <b-button variant="outline-primary" class="text-center" v-on:click="setRequirementsSelected('none')">none</b-button>
                </b-button-group>
              <b-card class="m-3">
              <b-form-checkbox-group
                      v-model="requirementsSelected"
                      :options="requirementsOptionsDynamic"
                      button-variant="light"
                      size="md"
                      class="d-flex flex-column mx-auto text-left"
                      switches
                ></b-form-checkbox-group>
              </b-card>
            </div>
          </b-sidebar>
          <b-btn class="filter-button" @click="resetFilters">reset filters</b-btn>
        </b-col>
        <b-col align-self="center" cols="2" class="cols text-center" align-v="center">
        </b-col>
      </b-row>
      <b-row class="mt-4">
          <b-col sm="2" align-content="center"></b-col>
          <b-col sm="2" align-content="center">
            <b-pagination
              v-model="currentPage"
              :total-rows="annotationsShow.length"
              :per-page="perPage"
            ></b-pagination>
          </b-col>
          <b-col lg="4" align-content="center">
            <b-dropdown id="dropdown-download" text="Download" class="filter-button">
              <b-dropdown-item v-on:click="download('all')">All</b-dropdown-item>
              <b-dropdown-item v-on:click="download('filter')">Filter Selection</b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col fluid sm="2" align-content="center" class="mt-4 text-right" justify="center" align="center">
            showing {{annotationsShow.length}} of total {{annotations.length}}
          </b-col>
          <b-col sm="2"></b-col>
        </b-row>
        <b-row>
          <b-col sm="2"></b-col>
          <b-col sm="4">
            <b-form inline class="">
              sort by:
              <b-form-select v-model="sortSelected" :options="sortOptions" class="mt-2 mb-2 ml-2" wi></b-form-select>
            </b-form>
          </b-col>
          <b-col sm="2"></b-col>
        </b-row>
        <b-row>
          <b-col cols="2"></b-col>
          <b-col cols="8">
          <b-card class="mb-3" v-for="annotation in annotationsShowPage" :key="'annotation-' + annotation.id">
            <annotation-item
              :annotation="annotation"
              :areas="areas"
              :entities="entities"
              :requirements="requirements"
            ></annotation-item>
          </b-card>
          </b-col>
          <b-col cols="2"></b-col>
        </b-row>
        <b-row>
          <b-col cols="2"></b-col>
          <b-col cols="2">
            <b-pagination
              v-model="currentPage"
              :total-rows="annotationsShow.length"
              :per-page="perPage"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
  </div>
</div>
</template>

<script>
import _ from 'lodash'
import ac from '@/libs/accesscontrol'
import countries from '@/libs/countries'
import moment from 'moment'
import zipcelx from 'zipcelx'
import AnnotationItem from '@/components/AnnotationSimple.vue'
import workflow from '@/workflows/annotation'

export default {
  name: 'RegObligations',
  components: {
    AnnotationItem
  },
  computed: {
    annotationsShow: function () {
      let results = this.annotations

      if (this.searchFilter.length > 2) {
        results = results.filter(annotation => {
          const term = annotation.regulation.regulator.name + ' ' + annotation.regulation.name_short + ' ' + annotation.name + ' ' + annotation.context + ' ' + annotation.description + ' ' + annotation.citation + ' ' + annotation.text
          return term.toUpperCase().match(this.searchFilter.toUpperCase())
        })
      }

      // filter by country
      let newResult = []
      _.each(this.countrySelected, row => {
        let filteredResult = []
        filteredResult = _.filter(results, ['regulation.country_code', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      // filter by regulators
      newResult = []
      _.each(this.regulatorSelected, row => {
        let filteredResult = []
        filteredResult = _.filter(results, ['regulation.regulator.id', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      // filter by regulations
      newResult = []
      _.each(this.regulationSelected, row => {
        let filteredResult = []
        filteredResult = _.filter(results, ['regulation_id', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      // filter by workflow status
      newResult = []
      _.each(this.workflowStatusSelected, row => {
        let filteredResult = []
        filteredResult = _.filter(results, ['status_id', row])
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      // filter by type
      newResult = []
      _.each(this.typeFilterSelected, row => {
        let filteredResult = []
        _.filter(results, x => {
          if (row === 0) {
            if (!x.type_id) {
              filteredResult.push(x)
            }
          }
          if (x.type_id === row) {
            filteredResult.push(x)
          }
        })
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      // filter by area
      newResult = []
      _.each(this.areasSelected, row => {
        let filteredResult = []
        _.filter(results, x => {
          if (row === 0) {
            if (x.areas.length === 0) {
              filteredResult.push(x)
            }
          }
          if (_.find(x.areas, ['id', row])) {
            filteredResult.push(x)
          }
        })
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      // filter by entites
      newResult = []
      _.each(this.entitiesSelected, row => {
        let filteredResult = []
        _.filter(results, x => {
          if (row === 0) {
            if (x.entities.length === 0) {
              filteredResult.push(x)
            }
          }
          if (_.find(x.entities, ['id', row])) {
            filteredResult.push(x)
          }
        })
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      // filter by requirements
      newResult = []
      _.each(this.requirementsSelected, row => {
        let filteredResult = []
        _.filter(results, x => {
          if (row === 0) {
            if (x.requirements.length === 0) {
              filteredResult.push(x)
            }
          }
          if (_.find(x.requirements, ['id', row])) {
            filteredResult.push(x)
          }
        })
        _.each(filteredResult, x => {
          newResult.push(x)
        })
      })
      results = newResult

      let uniqueResult = _.uniqBy(results, 'id')
      uniqueResult = _.sortBy(uniqueResult, [this.sortSelected])
      return uniqueResult
    },
    annotationsShowPage: function () {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage
      return this.annotationsShow.slice(start, end)
    },
    areasOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotationsShow)) {
        rows = this.annotations
      }
      let result = []
      rows.forEach(x => {
        _.each(x.areas, y => {
          const row = { value: y.id, text: y.name }
          result.push(row)
        })
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result.unshift({ value: 0, text: "None" })
      return result
    },
    areasSelectedDisplay: function () {
      let result = this.areasSelected.length
      if (this.areasOptionsDynamic.length === this.areasSelected.length) {
        result = 'all'
      }
      return result
    },
    countryOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotations)) {
        rows = this.annotations
      }
      let result = []
      rows.forEach(x => {
        const row = { value: x.regulation.country_code, text: countries.find(y => y.alpha2 === x.regulation.country_code).country }
        result.push(row)
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      return result
    },
    countrySelectedDisplay: function () {
      let result = this.countrySelected.length
      if (this.countryOptionsDynamic.length === this.countrySelected.length) {
        result = 'all'
      }
      return result
    },
    entitiesOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotationsShow)) {
        rows = this.annotations
      }
      let result = []
      rows.forEach(x => {
        _.each(x.entities, y => {
          const row = { value: y.id, text: y.name }
          result.push(row)
        })
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result.unshift({ value: 0, text: "None" })
      return result
    },
    entitiesSelectedDisplay: function () {
      let result = this.entitiesSelected.length
      if (this.entitiesOptionsDynamic.length === this.entitiesSelected.length) {
        result = 'all'
      }
      return result
    },
    regulatorOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotationsShow)) {
        rows = this.annotations
      }
      let result = []
      rows.forEach(x => {
        const row = { value: x.regulation.regulator.id, text: x.regulation.regulator.name }
        result.push(row)
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      return result
    },
    regulatorSelectedDisplay: function () {
      let result = this.regulatorSelected.length
      if (this.regulatorOptionsDynamic.length === this.regulatorSelected.length) {
        result = 'all'
      }
      return result
    },
    regulationOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotationsShow)) {
        rows = this.annotations
      }
      let result = []
      rows.forEach(x => {
        const row = { value: x.regulation_id, text: x.regulation.name_short }
        result.push(row)
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      return result
    },
    regulationSelectedDisplay: function () {
      let result = this.regulationSelected.length
      if (this.regulationOptionsDynamic.length === this.regulationSelected.length) {
        result = 'all'
      }
      return result
    },
    requirementsOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotationsShow)) {
        rows = this.annotations
      }
      let result = []
      rows.forEach(x => {
        _.each(x.requirements, y => {
          const row = { value: y.id, text: y.name }
          result.push(row)
        })
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result.unshift({ value: 0, text: "None" })
      return result
    },
    requirementsSelectedDisplay: function () {
      let result = this.requirementsSelected.length
      if (this.requirementsOptionsDynamic.length === this.requirementsSelected.length) {
        result = 'all'
      }
      return result
    },
    typesOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotationsShow)) {
        rows = this.annotations
      }
      let result = []
      _.each(rows, x => {
        _.each(this.typeFilterOptions, y => {
          if (y.id === 0) {
            if (!result.includes(y)) {
              result.push(y)
            }
          }
          if (x.type_id === y.value) {
            if (!result.includes(y)) {
              result.push(y)
            }
          }
        })
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      result.unshift({ value: 0, text: "None" })
      return result
    },
    typesSelectedDisplay: function () {
      let result = this.typeFilterSelected.length
      if (this.typesOptionsDynamic.length === this.typeFilterSelected.length) {
        result = 'all'
      }
      return result
    },
    workflowStatusOptionsDynamic: function () {
      let rows = []
      if (Array.isArray(this.annotationsShow)) {
        rows = this.annotations
      }
      let result = []
      rows.forEach(x => {
        const row = { value: x.status_id, text: x.regulation.name_short }
        result.push(row)
      })
      result = _.uniqBy(result, 'value')
      result = _.sortBy(result, 'text')
      return result
    },
    workflowStatusSelectedDisplay: function () {
      let result = this.workflowStatusSelected.length
      if (this.workflowStatusOptionsDynamic.length === this.workflowStatusSelected.length) {
        result = 'all'
      }
      return result
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: async function () {
    this.$stat.log({ page: 'obligations', action: 'open obligations' })
    this.permission.read = ac.can(this.user.acgroups).readAny('obligation').granted
    try {
      if (this.permission.read) {
        const limit = 500
        let offset = 0
        let response = []
        let page = 0
        do {
          offset = page * limit
          const apiName = 'cosmos'
          const path = `/obligations/annotations/limit/${limit}/offset/${offset}`
          response = await this.$Amplify.API.get(apiName, path)
          const annotations = _.concat(this.annotations, response)
          this.annotations = annotations
          page++
          await this.$nextTick()
        } while (response.length === limit)
        const staticData = await this.$Amplify.API.get('cosmos', '/obligations/staticdata')

        const types = staticData.types
        const typeIds = []
        _.each(this.annotations, x => {
          if (x.type_id) {
            if (!typeIds.includes(x.type_id)) {
              typeIds.push(x.type_id)
            }
          }
        })
        _.each(types, x => {
          if (typeIds.includes(x.id)) {
            this.typeFilterOptions.push({ text: x.name, value: x.id })
          }
        })

        _.each(workflow, x => {
          if (x.id > 0) {
            this.workflowStatusOptions.push({ text: x.name, value: x.id })
          }
        })
        this.loading = false
        this.ready = true
        this.setCountriesSelected('all')
        this.setRegulatorsSelected('all')
        this.setRegulationsSelected('all')
        this.setWorkflowStatusSelected('all')
        this.setTypeFilterSelected('all')
        this.setAreasSelected('all')
        this.setEntitiesSelected('all')
        this.setRequirementsSelected('all')
        console.log(this.annotations[0])
      }
    } catch (e) {
      this.$logger.warn('getting reulation error', e)
    }
  },
  data () {
    return {
      areas: [],
      areasOptions: [],
      areasSelected: [],
      annotations: [],
      countrySelected: [],
      currentPage: 1,
      entities: [],
      entitiesOptions: [],
      entitiesSelected: [],
      loading: true,
      moment: moment,
      perPage: 20,
      permission: {
        read: false
      },
      ready: false,
      regulationSelected: [],
      regulatorSelected: [],
      requirements: [],
      requirementsOptions: [],
      requirementsSelected: [],
      searchFilter: '',
      sortSelected: 'name',
      sortOptions: [
        { value: 'id', text: 'id' },
        { value: 'name', text: 'name' }
      ],
      typeFilterOptions: [],
      typeFilterSelected: [],
      workflowStatusOptions: [],
      workflowStatusSelected: []
    }
  },
  methods: {
    resetFilters: function () {
      this.setCountriesSelected('all')
      this.setRegulatorsSelected('all')
      this.setRegulationsSelected('all')
      this.setWorkflowStatusSelected('all')
      this.setTypeFilterSelected('all')
      this.setAreasSelected('all')
      this.setEntitiesSelected('all')
      this.setRequirementsSelected('all')
      this.searchFilter = ''
    },
    download: function (type) {
      this.$logger.debug('loading started')
      const data = []
      const fields = [
        { field: 'regulation_id', name: 'regulation id' },
        { field: 'regulation_name_short', name: 'regulation name short' },
        { field: 'regulation_country_code', name: 'regulation country code' },
        { field: 'regulation_topics', name: 'regulation topics' },
        { field: 'regulation_tags', name: 'regulation tags' },
        { field: 'regulation_date_in_force', name: 'regulation date in force' },
        { field: 'regulation_date_last_amended', name: 'regulation date last amended' },
        { field: 'regulator', name: 'regulator' },
        { field: 'id', name: 'obligation id' },
        { field: 'scrollY', name: 'ordering in text' },
        { field: 'obligationtype', name: 'type' },
        { field: 'citation', name: 'citation' },
        { field: 'name', name: 'name' },
        { field: 'date_effective', name: 'effective date' },
        { field: 'description', name: 'description' },
        { field: 'context', name: 'context' },
        { field: 'standard', name: 'minimum standard' },
        { field: 'lagging', name: 'lagging market practice' },
        { field: 'market_practice', name: 'leading market practice' },
        { field: 'topic', name: 'topic' },
        { field: 'entities', name: 'entities' },
        { field: 'areas', name: 'functions' },
        { field: 'requirements', name: 'requirements' },
        { field: 'status_id', name: 'status id' },
        { field: 'status_name', name: 'status name' },
        { field: 'status_created_at', name: 'status created at' },
        { field: 'status_created_by', name: 'status created by' },
        { field: 'created_at', name: 'created at' },
        { field: 'created_by', name: 'created by' },
        { field: 'updated_at', name: 'updated at' },
        { field: 'updated_by', name: 'updated by' }
      ]
      const headings = _.map(fields, field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      let dlSource = ""
      if (type === "full") {
        dlSource = this.annotations
      } else if (type === "filter") {
        dlSource = this.annotationsShow
      } else {
        dlSource = this.annotations
      }
      _.each(dlSource, annotation => {
        const row = _.map(fields, field => {
          if (field.field === 'date_effective') {
            let dateEffective = ''
            if (annotation[field.field]) {
              dateEffective = moment(annotation[field.field]).format('YYYY-MM-DD')
            }
            return { value: dateEffective, type: 'string' }
          }
          if (field.field === 'regulation_name_short') {
            return { value: annotation.regulation.name_short, type: 'string' }
          }
          if (field.field === 'regulation_country_code') {
            return { value: annotation.regulation.country_code, type: 'string' }
          }
          if (field.field === 'regulation_topics') {
            return { value: annotation.regulation.regtopics.map(x => x.name), type: 'string' }
          }
          if (field.field === 'regulation_tags') {
            return { value: annotation.regulation.tags.map(x => x.name), type: 'string' }
          }
          if (field.field === 'regulation_date_in_force') {
            return { value: moment(annotation.regulation.date_in_force).format('YYYY-MM-DD'), type: 'string' }
          }
          if (field.field === 'regulation_date_last_amended') {
            return { value: moment(annotation.regulation.date_last_amended).format('YYYY-MM-DD'), type: 'string' }
          }
          if (field.field === 'regulator') {
            return { value: annotation.regulation.regulator.name, type: 'string' }
          }
          if (field.field === 'text') {
            return { value: annotation[field.field].replace(/\s\s+/g, ' '), type: 'string' }
          }
          if (field.field === 'status_name') {
            return { value: workflow.find(x => x.id === annotation.status_id).name, type: 'string' }
          }
          if (field.field === 'obligationtype') {
            if (annotation[field.field]) {
              return { value: annotation[field.field].name, type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'entities') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'areas') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          if (field.field === 'requirements') {
            if (annotation[field.field].length > 0) {
              return { value: annotation[field.field].map(x => x.name), type: 'string' }
            } else {
              return { value: '', type: 'string' }
            }
          }
          return { value: annotation[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'annotations', sheet: { data: data } }
      zipcelx(config)
    },
    setCountriesSelected: function (button) {
      if (button === "all") {
        this.countrySelected = []
        _.each(this.annotations, x => {
          if (!this.countrySelected.includes(x.regulation.country_code)) {
            this.countrySelected.push(x.regulation.country_code)
          }
        })
      }
      if (button === "none") {
        this.countrySelected = []
      }
    },
    setRegulatorsSelected: function (button) {
      if (button === "all") {
        this.regulatorSelected = []
        _.each(this.annotations, x => {
          if (!this.regulatorSelected.includes(x.regulation.regulator.id)) {
            this.regulatorSelected.push(x.regulation.regulator.id)
          }
        })
      }
      if (button === "none") {
        this.regulatorSelected = []
      }
    },
    setRegulationsSelected: function (button) {
      if (button === "all") {
        this.regulationSelected = []
        _.each(this.annotations, x => {
          if (!this.regulationSelected.includes(x.regulation_id)) {
            this.regulationSelected.push(x.regulation_id)
          }
        })
      }
      if (button === "none") {
        this.regulationSelected = []
      }
    },
    setWorkflowStatusSelected: function (button) {
      if (button === "all") {
        this.workflowStatusSelected = []
        _.each(this.annotations, x => {
          if (!this.workflowStatusSelected.includes(x.status_id)) {
            this.workflowStatusSelected.push(x.status_id)
          }
        })
      }
      if (button === "none") {
        this.workflowStatusSelected = []
      }
    },
    setTypeFilterSelected: function (button) {
      if (button === "all") {
        this.typeFilterSelected = []
        _.each(this.annotations, x => {
          if (!this.typeFilterSelected.includes(x.type_id)) {
            this.typeFilterSelected.push(x.type_id)
          }
        })
      }
      this.typeFilterSelected.push(0)
      if (button === "none") {
        this.typeFilterSelected = []
      }
    },
    setAreasSelected: function (button) {
      if (button === "all") {
        this.areasSelected = []
        _.each(this.annotations, x => {
          _.each(x.areas, y => {
            if (!this.areasSelected.includes(y.id)) {
              this.areasSelected.push(y.id)
            }
          })
        })
        this.areasSelected.push(0)
      }
      if (button === "none") {
        this.areasSelected = []
      }
    },
    setEntitiesSelected: function (button) {
      if (button === "all") {
        this.entitiesSelected = []
        _.each(this.annotations, x => {
          _.each(x.entities, y => {
            if (!this.entitiesSelected.includes(y.id)) {
              this.entitiesSelected.push(y.id)
            }
          })
        })
        this.entitiesSelected.push(0)
      }
      if (button === "none") {
        this.entitiesSelected = []
      }
    },
    setRequirementsSelected: function (button) {
      if (button === "all") {
        this.requirementsSelected = []
        _.each(this.annotations, x => {
          _.each(x.requirements, y => {
            if (!this.requirementsSelected.includes(y.id)) {
              this.requirementsSelected.push(y.id)
            }
          })
        })
        this.requirementsSelected.push(0)
      }
      if (button === "none") {
        this.requirementsSelected = []
      }
    }
  }
}
</script>

<style>
body {
  padding-top: 100px;
}
h1 {
  margin: 15px;
}
.b-sidebar {
  width: 40%;
  min-width: 320px;
}
.filter {
  max-width: 253px;
}
.filter-button {
  margin-left: 10px;
  margin-bottom: 15px;
}
.search {
  margin: 15px;
}
</style>
