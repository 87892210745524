import { render, staticRenderFns } from "./Obligation.vue?vue&type=template&id=2561deb2&scoped=true&"
import script from "./Obligation.vue?vue&type=script&lang=js&"
export * from "./Obligation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2561deb2",
  null
  
)

export default component.exports